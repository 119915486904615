.sec-7 {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ff6c23;
  overflow: hidden;
}

.sec-7 p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Font1;
  gap: 5px;
  margin-bottom: 10px;
}
.sec-7 p a {
  color: #fff;
  text-decoration: none;
}

.sec-7 .Main7 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.Main7 .M7Block1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.Main7 .M7Block1 img {
  width: 287px;
  height: auto;
  flex-shrink: 0;
  border-radius: 15px;
}

.M7Block1 h1 {
  font-family: Font1;
}

.M7Block2 {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.M7Block2 button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 200px;
  color: #fff;
  font-size: 20px;
}

@media only screen and (max-width: 650px) {
  .Main7 {
    flex-direction: column;
    gap: 50px;
  }
  .sec-7 {
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
