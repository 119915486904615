.sec-1 {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.background-video {
  width: 100%;
  min-height: 600px;
  object-fit: cover;
  z-index: -1;
  position: absolute;
}

.sec-1 h1 {
  color: #fff;
  font-size: 100px;
  font-family: Font1;
  z-index: 1;
}
.overlay-text {
  color: #fff;
  font-size: 80px;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 600px;
  display: flex;
  background: #0000008c;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.overlay-text p {
  font-size: 30px;
  margin-top: 25px;
  color: #00b9f2;
}
.sec-1 .h2 {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  font-family: Font1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
}
.sec-1 .sec-1-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.sec-1-btn button {
  border-radius: 10px;
  background: #00b9f2;
  border: none;
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  font-size: 25px;
  font-family: Font1;
  font-weight: 550;
  cursor: pointer;
}
.sec-1 .CBtn {
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ff6c23;
  color: #fff;
  font-size: 25px;
  font-family: Font1;
  font-weight: 550;
  cursor: pointer;
  border: none;
}

@media only screen and (max-width: 780px) {
  .sec-1 h1 {
    font-size: 65px;
  }

  .sec-1 .h2 {
    font-size: 30px;
  }

  .sec-1 .h2 svg {
    width: 90px;
  }

  .sec-1 :nth-child(3) {
    width: 350px;
  }

  .sec-1-btn button {
    width: 150px;
    height: 35px;
    font-size: 20px;
  }

  .sec-1 :nth-child(5) {
    width: 150px;
    height: 35px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 460px) {
  .sec-1 {
    width: 100%;
    height: fit-content;
  }
  .background-video {
    width: 100%;
    height: 300px;
  }
  .sec-1 h1 {
    font-size: 50px;
  }
  .overlay-text {
    color: white;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    z-index: 1;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    background: #0000008c;
  }

  .sec-1 .h2 {
    font-size: 25px;
  }

  .sec-1 .h2 svg {
    width: 80px;
  }

  .sec-1 .overlay-text svg {
    width: 300px;
  }
  .sec-1-btn button {
    width: 150px;
    height: 35px;
    font-size: 20px;
  }
  .sec-1 .CBtn {
    width: 150px;
    height: 35px;
    font-size: 20px;
  }
}
