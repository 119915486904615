.sec-2-extra {
  width: 100%;
  height: 800px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding-top: 50px;
}

.sec-2-extra h1 {
  color: #ff6c23;
  text-align: center;
  font-family: Font1;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.MainServices {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  gap: 200px;
}

.MainServices .ButtonsBlock {
  width: 500px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  gap: 20px;
}

.ButtonsBlock button {
  width: 350px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 2px #ff6c23 solid;
  background: transparent;
  box-shadow: 0px 10px 29px 0px rgba(100, 100, 111, 0.2);
  cursor: pointer;
  font-size: 25px;
  color: #ff6c23;
}

.MainServices .ContentBlock {
  width: 500px;
  height: 500px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 29px 0px rgba(100, 100, 111, 0.2);
}
.ButtonsBlock .activeButton {
  color: #00b9f2;
  border-bottom: 2px solid #00b9f2;
}

@media only screen and (max-width: 1000px) {
  .sec-2-extra {
    height: fit-content;
    padding-bottom: 10px;
  }
  .MainServices {
    flex-direction: column;
    gap: 50px;
  }
  .MainServices .ContentBlock {
    width: 90%;
    height: fit-content;
    padding-bottom: 10px;
  }
}
