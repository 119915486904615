.Home-slider {
  overflow: hidden;
}
.Home-image-container img {
  max-width: 95%;
  max-height: auto;
  display: block;
  position: absolute;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  border-radius: 15px;
}
.Home-image-container img.active {
  opacity: 1;
}
