.slider {
  overflow: hidden;
}
.image-container img {
  max-width: fit-content;
  max-height: 250px;
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.image-container img.active {
  opacity: 1;
}
