.sec-6 {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.sec-6 h1 {
  color: #00b9f2;
  text-align: center;
  font-family: Font1;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 50px;
}

.MainContact {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 250px;
  margin-top: 50px;
}

.MainContact .CBlock1 {
  width: 400px;
  height: 360px;
  border-radius: 11.402px;
  background: #fff;
  box-shadow: 0px 15.963px 66.134px 0px rgba(100, 100, 111, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.MainContact .CBlock1 a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 300px;
  height: 50px;
  color: #7c7c7c;
  cursor: pointer;
  text-decoration: none;
  font-family: Font1;
}

.MainContact .CBlock2 {
  width: 500px;
  height: 500px;
  border-radius: 11.402px;
  background: #fff;
  box-shadow: 0px 15.963px 66.134px 0px rgba(100, 100, 111, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.MainContact .CBlock2 input {
  width: 350px;
  height: 40px;
  border: none;
  border-bottom: 1px #00b9f2 solid;
  font-family: Font1;
  outline: none;
}

.MainContact .CBlock2 textarea {
  width: 350px;
  height: 100px;
  border: none;
  border-bottom: 1px #00b9f2 solid;
  font-family: Font1;
  outline: none;
}

.MainContact .CBlock2 button {
  width: 150px;
  height: 35px;
  border: none;
  background: #00b9f2;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 1250px) {
  .sec-6 {
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: column;
  }
  .MainContact {
    flex-direction: column-reverse;
    gap: 50px;
  }
}

@media only screen and (max-width: 550px) {
  .MainContact {
    width: 100%;
  }

  .MainContact .CBlock1 {
    width: 95%;
  }
  .MainContact .CBlock2 {
    width: 95%;
  }
  .MainContact .CBlock2 input {
    width: 80%;
  }
  .MainContact .CBlock2 textarea {
    width: 80%;
  }
  .sec-6 .S1 {
    width: 80%;
  }
}
