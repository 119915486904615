.sec-3 {
  width: 100%;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  overflow: hidden;
}

.sec-3 div {
  width: 150px;
  height: 50px;
  background: #3f3f3f;
}
