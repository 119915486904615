.sec-4 {
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  margin-top: 50px;
  gap: 50px;
}

.AboutusContent {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 350px;
}

.AboutusContent .block1 {
  width: 400px;
  height: 350px;
  flex-shrink: 0;
  background: #fff;
  overflow: hidden;
  padding-left: 10px;
  padding-top: 25px;
}

.AboutusContent .block2 {
  width: 550px;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.AboutusContent .block2 h1 {
  width: 100%;
  color: #00b9f2;
  font-family: Font1;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.AboutusContent .block2 p {
  width: 100%;
  color: #7c7c7c;
  font-family: Font1;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.AboutusContent .block2 .AboutusBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  gap: 20px;
}

.AboutusBtn button {
  width: 150px;
  height: 35px;
  border: none;
  background: #00b9f2;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.AboutusBtn :nth-child(2) {
  background: #ff6c23;
}

@media only screen and (max-width: 1400px) {
  .sec-4 {
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .AboutusContent {
    flex-direction: column-reverse;
    gap: 100px;
  }
}

@media only screen and (max-width: 620px) {
  .AboutusContent .block2 {
    width: 90%;
  }

  .AboutusContent .block2 h1 {
    font-size: 35px;
  }

  .AboutusContent .block2 h1 svg {
    width: 160px;
  }

  .AboutusContent .block2 p {
    font-size: 20px;
    text-overflow: clip;
  }

  .AboutusContent .block2 .AboutusBtn {
    gap: 20px;
  }
}

@media only screen and (max-width: 460px) {
  .sec-4 {
    height: fit-content;
    padding-top: 20px;
    overflow: hidden;
    padding-bottom: 20px;
  }

  .sec-4 svg {
    width: 80%;
  }

  .AboutusContent .block1 {
    width: 350px;
    height: 350px;
  }
}
