.sec-2 {
  width: 100%;
  height: 650px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  gap: 50px;
}

.sec-2 h1 {
  color: #ff6c23;
  text-align: center;
  font-family: Font1;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CatalogBlocks {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.CatalogBlocks div {
  border-radius: 10.273px;
  border-bottom: 2px solid #ff6c23;
  background: #fff;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 29px 0px rgba(100, 100, 111, 0.2);
}

.CatalogBlocks div img {
  height: 95%;
  width: auto;
}

.sec-2 button {
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #ff6c23;
  border: none;
  color: #fff;
  font-family: Font1;
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 1250px) {
  .sec-2 {
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .CatalogBlocks {
    flex-direction: column;
    gap: 50px;
  }
}
