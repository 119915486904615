.Csec-1 {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
}

.Csec-1 h1 {
  color: #ff6c23;
  font-size: 50px;
  font-family: Font1;
}

.Csec-1 a {
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #ff6c23;
  border: none;
  color: #fff;
  font-family: Font1;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.Csec-2 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
  overflow: hidden;
  gap: 83px;
}

.Csec-2 .Product {
  width: 90%;
  height: 300px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 10px 29px 0px rgba(100, 100, 111, 0.2);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Product img {
  width: auto;
  height: 90%;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.Product img:hover {
  transform: scale(1.5);
}
.Csec-2 .Product img:nth-of-type(2) {
  height: 150px;
}

.info {
  width: 500px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.info p {
  font-size: 20px;
  border-bottom: 1px black solid;
  width: 100%;
  font-weight: bold;
  font-family: Font1;
}
.Color {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.Color ul {
  border-radius: 30px;
}
@media only screen and (max-width: 1000px) {
  .Csec-2 .Product {
    width: 95%;
    height: fit-content;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .Csec-2 .Product img {
    width: auto;
    height: 250px;
  }

  .info {
    width: 90%;
    margin-top: 20px;
  }
  .Csec-2 .Product .I38 {
    width: 250px;
    height: auto;
  }
  .Color {
    gap: 3px;
  }
}
