.Maind {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Maind button {
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  border: none;
  padding: 5px;
  background: transparent;
  cursor: pointer;
  color: #ff6c23;
  transition: 0.8s;
}
.Maind button:hover {
  border-bottom: 1px #00b9f2 solid;
}
