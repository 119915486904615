.Services {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 25px;
  gap: 20px;
}
.Services h1 {
  width: 100%;
  height: fit-content;
  color: #00b9f2;
}

.Services .Content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 50px;
}

.Content p {
  width: 100%;
  color: #7c7c7c;
  font-size: 20px;
  text-align: start;
  text-overflow: clip;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.Content img {
  width: 250px;
  height: auto;
}
@media only screen and (max-width: 1000px) {
  .Services {
    gap: 10px;
  }
  .Services h1 {
    font-size: 25px;
  }
  .Content p {
    font-size: 18px;
  }
}
