.sec-5 {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 350px;
  overflow: hidden;
}

.Block3 {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.Block3 h1 {
  width: 100%;
  color: #00b9f2;
  font-family: Font1;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Block3 p {
  color: #7c7c7c;
  width: 100%;
  font-family: Font1;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.TechnologiesBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  gap: 20px;
}

.TechnologiesBtn button {
  width: 150px;
  height: 35px;
  border: none;
  background: #00b9f2;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.TechnologiesBtn :nth-child(1) {
  background: #ff6c23;
}

.Block4 {
  width: 400px;
  height: 350px;
  flex-shrink: 0;
  background: #fff;
  padding-top: 25px;
  overflow: hidden;
}

@media only screen and (max-width: 1400px) {
  .sec-5 {
    flex-direction: column;
    height: fit-content;
    gap: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 620px) {
  .Block3 {
    width: 90%;
  }

  .Block3 h1 {
    font-size: 35px;
  }

  .Block3 h1 svg {
    width: 225px;
  }

  .Block3 p {
    font-size: 20px;
    text-overflow: clip;
    height: fit-content;
  }
}

@media only screen and (max-width: 460px) {
  .sec-5 {
    overflow: hidden;
  }
  .Block4 {
    width: 350px;
    height: 350px;
  }
}
